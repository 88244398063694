/**
    suncor optimization tool css stylesheet
*/

.gsfBackground {
  border-color: #9b6998;
  font-size: 13px;
  margin-bottom: 17px;
}

.gsfFeedback {
  border-color: #7ff98a;
  font-size: 10px;
  margin-bottom: 6px;
}

/* region top navbar */
#navbar-wrapper {
  margin: 0;
}

.minimalize-styl-2 {
  padding: 4px 12px;
  margin: 0 10px;
  float: left;
}

#flowsheet-navbar {
  padding: 10px 0px 0px 0px;
  background-color: white;
}

.navbar-top-links {
  display: inline-block;
}

.navbar-top-links li a {
  padding: 0 10px 5px 10px;
  min-height: 20px;
  font-size: 13px;
}

.page-title {
  font-size: 17px;
  position: relative;
  top: 5px;
}

.logo {
  padding-left: 5px;
}

.nav-pills a {
  min-height: 30px;
  padding: 5px 10px !important;
}

.nav-pills li.active {
  border: none;
  background: transparent !important;
}

.navbar-top-links li:last-child {
  margin-right: 0;
}

.toggle-navigation {
  margin: 10px 0 0 17px;
}

.toggle-navigation > a {
  padding: 4px 12px;
}

/* endregion */

#flowsheet-workspace, #flowsheet, #gantt-chart {
  padding: 0;
}

.Default {
  color: #337AB7;
}

.SolverCalculated {
  font-weight: bold;
  background: lightgray;
}
.not-valid, .not-ready{
  border:  1px solid #e50000
}

.pw-margin {
  margin-right: 15px;
}

.pw-label {
  font-size: 12px
}

.no-border {
  border: none;
}

.no-padding {
  padding: 0;
}

.no-side-padding {
  padding-left: 0px;
  padding-right: 0px;
}

.tab-content-padding {
  padding: 16px 12px;
}

.constraints-active-label {
  text-align: right; position: relative;
}

.constraints-active-label > span {
  position: absolute; right: -25px; top: -20px; font-weight: bold;
}

.subtitle {
  font-weight: 600;
  font-size: 14px;
  color: #337AB7;
  margin-left: 45px;
  margin-bottom: 15px;
}
.subtitle-v2 {
  font-weight: 600;
  font-size: 14px;
  color: #337AB7;
  margin-bottom: 15px;
}

.subtitle-sm {
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 15px;
}

.underline {
  text-decoration: underline!important;
}

@media (max-width: 768px) {
  .nested-form-group {
    margin-top: 15px;
  }
}

.valign-middle {
  vertical-align: middle !important;
}

.separator {
  margin: 0 5px;
  border-left: 1px solid #ccc;
}

#reportManagerModal .sim-var-input > .col-xs-6 {
  padding-left: 5px;
  padding-right: 5px;
}

.side-padding-5x {
  padding-left: 5px;
  padding-right: 5px;
}

/** Generic styles */
.overflow-x-auto {
  overflow-x: auto !important;
}

.margin-bottom-xs {
  margin-bottom: 5px;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.blocky {
  height: -webkit-calc(100vh - 72px);
  height: -moz-calc(100vh - 72px);
  height: calc(100vh - 72px);
  background-color: rgb(243, 243, 244);;
}


@media(min-width: 768px) {
  .modal-xl {
    width: 750px;
  }
}

@media(min-width: 992px) {
  .modal-xl {
    width: 980px;
  }
}

@media(min-width: 1200px) {
  .modal-xl {
    width: 1190px;
  }
}

.validation-error {
  font-size: 11px;
  color: #ed5565;
}

.font-lighter {
  font-weight: lighter;
}

.p-l-none {
 padding-left: 0;
}

.p-r-none {
  padding-right: 0;
}

.f-s-1-5-em {
  font-size: 1.5em;
}

.p-t-5-x {
  padding-top: 5px!important;
}

.p-t-10-x {
  padding-top: 10px!important;
}

.m-y-10-x {
  margin-top: 10px!important;
  margin-bottom: 10px!important;
}

/* INSPINIA TEMPLATE OVERRIDES */

/* This fixes the issue with the last tab of the sheet selector of the spreadsheet not showing*/
.pace-done li.active {
  transition: none;
}
.text-danger {
  color: #ad0012 !important;
  font-weight: bold !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.p-h-0-important {
  padding-top: 0!important;
  padding-bottom: 0!important;
}
