/* You can add global styles to this file, and also import other style files */
@import url('../node_modules/bootstrap/dist/css/bootstrap.min.css');
@import url('../node_modules/datatables.net-bs/css/dataTables.bootstrap.css');
@import url('../node_modules/font-awesome/css/font-awesome.min.css');
@import url('assets/styles/inspinia.css');
@import url('assets/styles/suncor.css');
@import url('assets/styles/custom-icons.css');
@import url('assets/styles/bootstrap-polyfills.css');

/* The css from the og toastr package looks better */
@import url('../node_modules/ngx-toastr/toastr.css');
@import url('../node_modules/@grapecity/wijmo.styles/wijmo.css');
@import url('assets/styles/wijmo-flex-sheet.css');

