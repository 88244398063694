.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}


.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pl-2_5,
.px-2_5 {
  padding-left: 0.75rem !important;
}

.pr-2_5,
.px-2_5 {
  padding-right: 0.75rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}


.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}


.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
